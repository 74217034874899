import styled from 'styled-components'

export const ProductQuantityAdderWrapper = styled.section`
margin-top:20px;
>strong{
  display:block;
  margin-bottom:10px;
}
>form {
 display:flex;

}
`