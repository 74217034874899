import React from 'react'
import {GoogleMapQrapper} from './styles'
export function Map(){
  return (
     <>
     <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13001.836231082134!2d139.631919!3d35.443428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2843f3cbcae12a8d!2z6Z-T5Zu954S86IKJ44O744K144Og44Ku44On44OX44K144OrIOOBqeOChOOBmA!5e0!3m2!1sja!2sjp!4v1610948646085!5m2!1sja!2sjp" 
     width="100%" 
     height="350" 
     frameborder="0" 
     allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </>
  )
}

