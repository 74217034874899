import React from 'react'
import {TitleBlock2Wrapper} from './styles'
export function TitleBlock2() {
  return(
 <TitleBlock2Wrapper>
  {/* <span>No1</span> */}
  <h1>どやじのサムギョプサルの3つの魅力</h1>
  
 </TitleBlock2Wrapper>
  )
}