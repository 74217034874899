import React from 'react'
import {TitleBlockWrapper} from './styles'
export function TitleBlock() {
  return(
 <TitleBlockWrapper>
  {/* <span>No1</span> */}
  <h1>サムギョプサルを文化として伝えたい</h1>
  
 </TitleBlockWrapper>
  )
}