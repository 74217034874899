import styled from 'styled-components'

export const LeftCardRightImageWrapper = styled.div`
 display:flex;
`

export const LeftCard = styled.div`
flex:1;
text-align:center;

`

export const RightImage = styled.div`
flex:1;
`

